<template>
  <div>
    <b-container fluid class="content">
      <b-card no-body>
        <b-card-body>
          <b-form>
            <b-row style="height: 80px">
              <b-col cols="2">
                <base-input :label="$t('Store')">
                  <el-select
                    class="el-dropdown"
                    v-model="cur_store_ID"
                    @change="changeStore"
                    :placeholder="$t('All Stores')"
                  >
                    <el-option
                      v-for="store in storeList_dropdown"
                      :key="store.IDn"
                      :label="store.Name"
                      :value="store.IDn"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </b-col>
              <b-col cols="2">
                <base-input :label="$t('Department')">
                  <el-select
                    class="el-dropdown"
                    v-model="cur_department_ID"
                    @change="changeDepartment"
                    :placeholder="$t('All Departments')"
                  >
                    <el-option
                      v-for="dept in deptList_dropdown"
                      :key="dept.IDn"
                      :label="dept.Name"
                      :value="dept.IDn"
                    >
                    </el-option>
                  </el-select>
                </base-input>
                
              </b-col>
              <b-col cols="2">
                <base-input :label="$t('Category')">
                  <el-select
                    class="el-dropdown"
                    v-model="cur_category_ID"
                    @change="changeCategory"
                    :placeholder="$t('All Categories')"
                  >
                    <el-option
                      v-for="cat in catList_dropdown"
                      :key="cat.IDn"
                      :label="cat.Name"
                      :value="cat.IDn"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </b-col>
              <b-col cols="2">
                <base-input :label="$t('Product')">
                  <el-select
                    class="el-dropdown"
                    v-model="cur_product_ID"
                    :placeholder="$t('All Products')"
                  >
                    <el-option
                      v-for="prod in prodList_dropdown"
                      :key="prod.IDn"
                      :label="prod.Name"
                      :value="prod.IDn"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </b-col>
              <b-col cols="2">
                <base-input :label="'PLU / Barcode / ' + $t('Name')">
                  <b-form-input v-model="cur_PBarName" class="b-input" :placeholder="'PLU / Barcode / ' + $t('Name')"></b-form-input>
                </base-input>
              </b-col>
              <b-col cols="1">
                <base-input :label="$t('Min Qty')">
                  <b-row>
                    <b-col cols="12" style="padding-left: 0px;"><b-form-input v-model="cur_minQty" class="b-input" :placeholder="$t('Min')" type="number"></b-form-input></b-col>
                    <!-- <b-col cols="6" style="padding-left: 0px;"><b-form-input v-model="cur_maxQty" class="b-input" :placeholder="$t('Max')" type="number"></b-form-input></b-col> -->
                  </b-row>
                </base-input>
              </b-col>
              <b-col cols="1">
                <base-input :label="$t('Max Qty')">
                  <b-row>
                    <!-- <b-col cols="6" style="padding-left: 0px;"><b-form-input v-model="cur_minQty" class="b-input" :placeholder="$t('Min')" type="number"></b-form-input></b-col> -->
                    <b-col cols="12" style="padding-left: 0px;"><b-form-input v-model="cur_maxQty" class="b-input" :placeholder="$t('Max')" type="number"></b-form-input></b-col>
                  </b-row>
                </base-input>
              </b-col>
            </b-row>
            <b-row>
              <el-checkbox v-model="hideZero" :label="$t('Hide product with 0 quantity')" size="small" style="padding-left: 15px; margin-bottom: 15px"/>
            </b-row>
            <b-row >
              <b-col cols="1">
                <button class="btn btn-primary" @click.prevent="form_search">
                  {{ $t("Search") }}
                </button>
              </b-col>
              <!-- <b-col cols="1">
                <a v-bind:href="pdfBaseUrl + '/Web/Report/inventory_summary?' + 
                  'token=' + token + 
                  '&user_id=' + user_id +
                  '&store_id=' + this.cur_store_ID +
                  '&dept_id=' + this.cur_department_ID +
                  '&cat_id=' + this.cur_category_ID +
                  '&prod_id=' + this.cur_product_ID + 
                  '&pBarName=' + this.cur_PBarName + 
                  '&minQty=' + this.cur_minQty + 
                  '&maxQty=' + this.cur_maxQty + 
                  '&hideZero=' + this.hideZero
                  " class="btn btn-primary" target="_blank" style="margin-bottom: 5px">PDF</a>
              </b-col> -->
              <b-col cols="1">
                <xlsx-workbook>
                  <xlsx-sheet
                    :collection="sheet.data"
                    v-for="sheet in sheets"
                    :key="sheet.name"
                    :sheet-name="sheet.name"
                  />
                  <xlsx-download filename="dayend.xlsx">
                    <button class="btn btn-primary">XLSX</button>
                  </xlsx-download>
                </xlsx-workbook>
              </b-col>
              
            </b-row>
          </b-form>
        </b-card-body>
      </b-card>
    </b-container>
    <b-container fluid>
      <b-card>
        <b-card-body :style="{ overflowX: 'auto' }">
          <div v-bind:style="{ width: report_width }">
            <b-row>
              <b-col cols="2">
                {{ $t('Store') + ': ' }} &emsp; <span style="font-weight: bold;">{{ cur_store_ID_searched }}</span>
              </b-col>  
              <b-col cols="3">
                {{ $t('Department') + ': ' }} &emsp; <span style="font-weight: bold;">{{ cur_department_ID_searched }}</span> 
              </b-col>
              <b-col cols="3">
                {{ $t('Category') + ': ' }} &emsp; <span style="font-weight: bold;">{{ cur_category_ID_searched }}</span>
              </b-col>
              <b-col cols="4">
                {{ $t('Product') + ': ' }} &emsp; <span style="font-weight: bold;">{{ cur_product_ID_searched }}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="8">
                &ensp;
              </b-col>
              <b-col cols="4">
                {{ $t("Quantity") + ' = 0 : ' }}  &emsp; <span v-if="hideZero_searched" style="font-weight: bold;">{{ $t("Hide") }}</span> <span v-else style="font-weight: bold;">{{ $t("Show") }}</span>
              </b-col>
            </b-row>
            <b-row v-if="cur_PBarName_searched">
              <b-col cols="8">
                &ensp;
              </b-col>
              <b-col cols="4">
                <div>
                  {{ $t('Keyword') + ': ' }} &emsp; <span style="font-weight: bold;">{{ cur_PBarName_searched }}</span>
                </div>
              </b-col>
            </b-row>
            <b-row  v-if="cur_minQty_searched || cur_maxQty_searched">
              <b-col cols="8">
                &ensp;
              </b-col>
              <b-row class="col-4">
                <b-col cols="6">{{ $t('Min Qty') + ': ' }} &emsp; <span style="font-weight: bold;">{{ cur_minQty_searched }}</span></b-col>
                <b-col cols="6">{{ $t('Max Qty') + ': ' }} &emsp; <span style="font-weight: bold;">{{ cur_maxQty_searched }}</span></b-col>
              </b-row>
            </b-row>
          </div>
          <div v-bind:style="{ width: report_width }">
            <!-- Table Headers -->
            <b-row v-bind:style="{ backgroundColor: 'lightGrey' }">
              <b-row class="col-5">
                <b-col cols="3">
                  <div v-bind:style="headerStyleObject" style="text-align: center">{{ $t('PLU') }}</div>
                </b-col>
                <b-col cols="6">
                  <div v-bind:style="headerStyleObject" style="text-align: center">{{ $t('Name') }}</div>
                </b-col>
                <b-col cols="3">
                  <div v-bind:style="headerStyleObject" style="text-align: left; margin-left: 1vw">{{ $t('Barcode') }}</div>
                </b-col>
              </b-row>
              <b-row class="col-7">
                <b-col>
                  <div v-bind:style="headerStyleObject" style="text-align: right; margin-right: 1vw">{{ $t('MSRP') }}</div>
                </b-col>
                <b-col>
                  <div v-bind:style="headerStyleObject" style="text-align: right; margin-right: 1vw">{{ $t('In Stock') }}</div>
                </b-col>
                <b-col>
                  <div v-bind:style="headerStyleObject" style="text-align: right; margin-right: 1vw">{{ $t('On Order') }}</div>
                </b-col>
                <b-col>
                  <div v-bind:style="headerStyleObject" style="text-align: right; margin-right: 1vw">{{ $t('On Hold') }}</div>
                </b-col>
                <b-col>
                  <div v-bind:style="headerStyleObject" style="text-align: right; margin-right: 1.5vw">{{ $t('Stock Value') }}</div>
                </b-col>
              </b-row>
            </b-row>
            <!-- Table Contents -->
            <template v-for="(store, store_key) in list_data" >
              <b-row v-bind:key="store_key" v-bind:style="{marginTop: '5px'}">
                <b-col class="col-12">
                  <div v-bind:style="titleStyleObject">
                    {{ $t('Store') + ': ' + store['LocationName'] }}
                  </div>
                </b-col>
                <b-col class="col-12">
                  <template v-for="(category, category_key) in store['LocationData']">
                    <b-row v-bind:key="category_key" v-bind:style="{marginTop: '5px'}">
                      <b-col class="col-12">
                        <div v-bind:style="titleStyleObject" style="font-weight: normal; text-decoration: underline;">
                          {{ $t('Category') + ': ' + category['CategoryName'] }}
                        </div>
                      </b-col>
                      <b-col class="col-12">
                        <template v-for="(list, list_key) in category['CategoryData']">
                          <b-row v-bind:key="list_key" v-bind:style="{ marginTop: '5px' }">
                            <b-row class="col-5">
                              <b-col cols="3">
                                <div v-bind:style="cellStyleObject" style="text-align: left; margin-left: -10px">{{ list['PLU'] }}</div>
                              </b-col>
                              <b-col cols="6">
                                <div v-bind:style="cellStyleObject" style="text-align: center; margin-left: -20px">{{ list['Name1'] }}</div>
                              </b-col>
                              <b-col cols="3">
                                <div v-bind:style="cellStyleObject" style="text-align: left">{{ list['Barcode'] }}</div>
                              </b-col>
                            </b-row>
                            <b-row class="col-7">
                              <b-col>
                                <div v-bind:style="cellStyleObject" style="text-align: right; margin-left: 2.3vw">{{ "$" + list['APrice'] }}</div>
                              </b-col>
                              <b-col v-if="list['Qty'] >= 0">
                                <div v-bind:style="cellStyleObject" style="text-align: right; margin-left: 2.3vw">{{ list['Qty'] }}</div>
                              </b-col>
                              <b-col v-else>
                                <div v-bind:style="cellStyleObject" style="text-align: right; margin-left: 2.3vw; font-weight: bold">{{ list['Qty'] }}</div>
                              </b-col>
                              <b-col>
                                <div v-bind:style="cellStyleObject" style="text-align: right; margin-left: 3vw">{{ list['OnOrder'] }}</div>
                              </b-col>
                              <b-col>
                                <div v-bind:style="cellStyleObject" style="text-align: right; margin-left: 3vw">{{ list['OnHold'] }}</div>
                              </b-col>
                              <b-col>
                                <div v-bind:style="cellStyleObject" style="text-align: right; margin-left: 2.5vw">{{ "$ " + list['StockValue'] }}</div>
                              </b-col>
                            </b-row>
                          </b-row>
                        </template>
                      </b-col>
                    </b-row>
                  </template>
                </b-col>
              </b-row>
            </template>
          </div>
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>
<script>
// import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import {
  Table,
  TableColumn,
  Button,
  Pagination,
  Select,
  Option,
  OptionGroup,
  Autocomplete,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
} from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Pagination.name]: Pagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Autocomplete.name]: Autocomplete,
    [Checkbox.name]: Checkbox,
    [CheckboxButton.name]: CheckboxButton,
    [CheckboxGroup.name]: CheckboxGroup,
    [flatPicker.name]: flatPicker,
    // XlsxWorkbook,
    // XlsxSheet,
    // XlsxDownload,
  },
  data() {
    return {
      configs: {
        from: {
          allowInput: false,
          enableTime: false,
          noCalendar: false,
          dateFormat: "Y-m-d",
          maxDate: "today",
          time_24hr: true,
        },
        to: {
          allowInput: false,
          enableTime: false,
          noCalendar: false,
          dateFormat: "Y-m-d",
          maxDate: "today",
          time_24hr: true,
        },
      },

      markStyleObject: {
        textAlign: "left",
        fontWeight: "bold",
        fontSize: "20px",
      },
      headerStyleObject: {
        fontWeight: "bold",
        fontSize: "17px",
      },
      cellStyleObject: {
        fontSize: "17px",
      },
      titleStyleObject: {
        textAlign: "left",
        // textDecoration: "underline",
        fontWeight: "bold",
        fontSize: "22px",
      },
      nameStyleObject: {
        textAlign: "left",
        fontWeight: "bold",
      },

      token: "",
      user_id: 0,
      onlyself: null,

      storeList: null,
      storeList_dropdown: null, 
      deptList: null,
      deptList_dropdown: null,
      catList: null,
      catList_dropdown: null,
      prodList: null,
      prodList_dropdown: null,

      list_data: null,
      inventory_data: null,
      po_product_data: null,
      data_column_count: null,

      from_date: null,
      to_date: null,
      from_date_searched: null,
      to_date_searched: null,

      cur_store_ID: null,
      cur_department_ID: null,
      cur_category_ID: null,
      cur_product_ID: null,
      cur_PBarName: null,
      cur_minQty: null,
      cur_maxQty: null,
      hideZero: false,

      cur_store_ID_searched: null,
      cur_department_ID_searched: null,
      cur_category_ID_searched: null,
      cur_product_ID_searched: null,
      cur_PBarName_searched: null,
      cur_minQty_searched: null,
      cur_maxQty_searched: null,
      hideZero_searched: false,

      sheets: [
        { name: "Sheet1", data: [[1, 2, 2], [1, 2, 2, 2], [], [1, 2, 2, 2]] },
      ],

      url: "",

      report_width: '100%',
      isSearchingByPO: false,
    };
  },
  watch: {
    current_page: function (newval, oldval) {
      this.get_list_data();
    },
    storeList: function (newval, oldval) {
      this.set_default_storeList_dropdown();
    },
    deptList: function (newval, oldval) {
      this.set_default_deptList_dropdown();
    },
    catList: function (newval, oldval) {
      this.set_default_catList_dropdown();
    },
    prodList: function (newval, oldval) {
      this.set_default_prodList_dropdown();
    },
  },
  computed: {
    pdfBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
  },
  methods: {
    changeStore(){
      let temp_deptList = [];
      let temp_catList = [];
      let temp_prodList = [];
      if(this.cur_store_ID){
        // Specific store selected
        // 1. Get Products of that store (data already exist in prodList)
        for(var i=0; i<this.prodList.length; i++){
          if(this.prodList[i].LocationID == this.cur_store_ID){
            temp_prodList.push(this.prodList[i]);
          }
        }
        // 2. Get Departments and Categories of that store
        for(var i=0; i<temp_prodList.length; i++){
          if(!temp_deptList.includes(temp_prodList[i].DepartmentIDn)){
            temp_deptList.push(temp_prodList[i].DepartmentIDn);
          }
          if(!temp_catList.includes(temp_prodList[i].CategoryIDn)){
            temp_catList.push(temp_prodList[i].CategoryIDn);
          }
        }
        temp_deptList.sort(function(a, b){return a - b});
        temp_catList.sort(function(a, b){return a - b});
        // 3. Set Department, Category, Product dropdown options
        this.set_deptList_dropdown(temp_deptList);
        this.set_catList_dropdown(temp_catList);
        this.set_prodList_dropdown(temp_prodList);
        // 4. Reset Department, Category, Product selection
        this.reset_cur_department_ID();
        this.reset_cur_category_ID();
        this.reset_cur_product_ID();
      } else {
        // All stores selected
        // 1. Set Department dropdown options
        this.set_default_deptList_dropdown();
        if(this.cur_department_ID){
          // 2. Get Category dropdown options to match Department
          for(var i=0; i<this.prodList.length; i++){
            if(this.prodList[i].DepartmentIDn == this.cur_department_ID){
              let include = false;
              for(var j=0; j<temp_prodList.length; j++){
                if(temp_prodList[j].IDn == this.prodList[i].ProductIDn){
                  include = true;
                  break;
                }
              }
              if(!include){
                temp_prodList.push(this.prodList[i]);
              }
            }
          }
          for(var i=0; i<temp_prodList.length; i++){
            if(!temp_catList.includes(temp_prodList[i].CategoryIDn)){
              temp_catList.push(temp_prodList[i].CategoryIDn);
            }
          }
          temp_catList.sort(function(a, b){return a - b});
          // 3. Set Category dropdown options
          this.set_catList_dropdown(temp_catList);
          // 4. Get Product dropdown options to match Category
          if(this.cur_category_ID){
            temp_prodList = [];
            for(var i=0; i<this.prodList.length; i++){
              if(this.prodList[i].DepartmentIDn == this.cur_department_ID && this.prodList[i].CategoryIDn == this.cur_category_ID){
                let include = false;
                for(var j=0; j<temp_prodList.length; j++){
                  if(temp_prodList[j].IDn == this.prodList[i].ProductIDn){
                    include = true;
                    break;
                  }
                }
                if(!include){
                  temp_prodList.push(this.prodList[i]);
                }
              }
            }
          }
          // 5. Set Product dropdown options
          this.set_prodList_dropdown(temp_prodList);
        } else {
          // 2. Set Category dropdown options
          this.set_default_catList_dropdown();
          if(this.cur_category_ID){
            // 3. Get Product dropdown options to match Category
            for(var i=0; i<this.prodList.length; i++){
              if(this.prodList[i].CategoryIDn == this.cur_category_ID){
                let include = false;
                for(var j=0; j<temp_prodList.length; j++){
                  if(temp_prodList[j].IDn == this.prodList[i].ProductIDn){
                    include = true;
                    break;
                  }
                }
                if(!include){
                  temp_prodList.push(this.prodList[i]);
                }
              }
            }
            // 4. Set Product dropdown options
            this.set_prodList_dropdown(temp_prodList);
          } else {
            // 3. Set Product dropdown options
            this.set_default_prodList_dropdown();
          }
        }
      }
    },
    changeDepartment(){
      let temp_catList = [];
      let temp_prodList = [];
      if(this.cur_department_ID){
        // Specific department selected
        // 1. Get Products of that department (data already exist in prodList)
        if(this.cur_store_ID){
          for(var i=0; i<this.prodList.length; i++){
            if(this.prodList[i].DepartmentIDn == this.cur_department_ID && this.prodList[i].LocationID == this.cur_store_ID){
              let include = false;
              for(var j=0; j<temp_prodList.length; j++){
                if(temp_prodList[j].IDn == this.prodList[i].ProductIDn){
                  include = true;
                  break;
                }
              }
              if(!include){
                temp_prodList.push(this.prodList[i]);
              }
            }
          }
        } else {
          for(var i=0; i<this.prodList.length; i++){
            if(this.prodList[i].DepartmentIDn == this.cur_department_ID){
              let include = false;
              for(var j=0; j<temp_prodList.length; j++){
                if(temp_prodList[j].IDn == this.prodList[i].ProductIDn){
                  include = true;
                  break;
                }
              }
              if(!include){
                temp_prodList.push(this.prodList[i]);
              }
            }
          }
        }
        // 2. Get Categories of that department
        for(var i=0; i<temp_prodList.length; i++){
          if(!temp_catList.includes(temp_prodList[i].CategoryIDn)){
            temp_catList.push(temp_prodList[i].CategoryIDn);
          }
        }
        temp_catList.sort(function(a, b){return a - b});
        // 3. Set Category, Product dropdown options
        this.set_catList_dropdown(temp_catList);
        this.set_prodList_dropdown(temp_prodList);
        // 4. Reset Category, Product selection
        this.reset_cur_category_ID();
        this.reset_cur_product_ID();
      } else {
        // All departments selected
        if(this.cur_store_ID){
          // 1. Get Products of that store (data already exist in prodList)
          if(this.cur_category_ID){
            for(var i=0; i<this.prodList.length; i++){
              if(this.prodList[i].CategoryIDn == this.cur_category_ID && this.prodList[i].LocationID == this.cur_store_ID){
                let include = false;
                for(var j=0; j<temp_prodList.length; j++){
                  if(temp_prodList[j].IDn == this.prodList[i].ProductIDn){
                    include = true;
                    break;
                  }
                }
                if(!include){
                  temp_prodList.push(this.prodList[i]);
                }
              }
            }
          } else {
            for(var i=0; i<this.prodList.length; i++){
              if(this.prodList[i].LocationID == this.cur_store_ID){
                let include = false;
                for(var j=0; j<temp_prodList.length; j++){
                  if(temp_prodList[j].IDn == this.prodList[i].ProductIDn){
                    include = true;
                    break;
                  }
                }
                if(!include){
                  temp_prodList.push(this.prodList[i]);
                }
              }
            }
          }
          // 2. Get Categories of that store
          let temp_prod_array_2 = [];   // if(this.cur_category_ID), temp_prod_array contains only products within this cat
                                        //  We need temp_prod_array_2 to contain all products in the store
          for(var i=0; i<this.prodList.length; i++){
            if(this.prodList[i].LocationID == this.cur_store_ID){
              let include = false;
              for(var j=0; j<temp_prod_array_2.length; j++){
                if(temp_prod_array_2[j].IDn == this.prodList[i].ProductIDn){
                  include = true;
                  break;
                }
              }
              if(!include){
                temp_prod_array_2.push(this.prodList[i]);
              }
            }
          }
          for(var i=0; i<temp_prod_array_2.length; i++){
            if(!temp_catList.includes(temp_prod_array_2[i].CategoryIDn)){
              temp_catList.push(temp_prod_array_2[i].CategoryIDn);
            }
          }
          temp_catList.sort(function(a, b){return a - b});
          // 3. Set Category, Product dropdown options
          this.set_catList_dropdown(temp_catList);
          this.set_prodList_dropdown(temp_prodList);
        } else {
          // 1. Set Category dropdown options
          this.set_default_catList_dropdown();
          if(this.cur_category_ID){
            // 2. Get Products of that category
            for(var i=0; i<this.prodList.length; i++){
              if(this.prodList[i].CategoryIDn == this.cur_category_ID){
                let include = false;
                for(var j=0; j<temp_prodList.length; j++){
                  if(temp_prodList[j].IDn == this.prodList[i].ProductIDn){
                    include = true;
                    break;
                  }
                }
                if(!include){
                  temp_prodList.push(this.prodList[i]);
                }
              }
            }
            // 3. Set Product dropdown options
            this.set_prodList_dropdown(temp_prodList);
          } else {
            // 2. Set Product dropdown options
            this.set_default_prodList_dropdown();
          }
        }
      }
    },
    changeCategory(){
      let temp_prodList = [];
      if(this.cur_category_ID){
        // Specific category selected
        // 1. Get Products of that category (data already exist in prodList)
        if(this.cur_department_ID){
          if(this.cur_store_ID){
            for(var i=0; i<this.prodList.length; i++){
              if(this.prodList[i].CategoryIDn == this.cur_category_ID && this.prodList[i].DepartmentIDn == this.cur_department_ID && this.prodList[i].LocationID == this.cur_store_ID){
                let include = false;
                for(var j=0; j<temp_prodList.length; j++){
                  if(temp_prodList[j].IDn == this.prodList[i].ProductIDn){
                    include = true;
                    break;
                  }
                }
                if(!include){
                  temp_prodList.push(this.prodList[i]);
                }
              }
            }
          } else {
            for(var i=0; i<this.prodList.length; i++){
              if(this.prodList[i].CategoryIDn == this.cur_category_ID && this.prodList[i].DepartmentIDn == this.cur_department_ID){
                let include = false;
                for(var j=0; j<temp_prodList.length; j++){
                  if(temp_prodList[j].IDn == this.prodList[i].ProductIDn){
                    include = true;
                    break;
                  }
                }
                if(!include){
                  temp_prodList.push(this.prodList[i]);
                }
              }
            }
          }
        } else {
          if(this.cur_store_ID){
            for(var i=0; i<this.prodList.length; i++){
              if(this.prodList[i].CategoryIDn == this.cur_category_ID && this.prodList[i].LocationID == this.cur_store_ID){
                let include = false;
                for(var j=0; j<temp_prodList.length; j++){
                  if(temp_prodList[j].IDn == this.prodList[i].ProductIDn){
                    include = true;
                    break;
                  }
                }
                if(!include){
                  temp_prodList.push(this.prodList[i]);
                }
              }
            }
          } else {
            for(var i=0; i<this.prodList.length; i++){
              if(this.prodList[i].CategoryIDn == this.cur_category_ID){
                let include = false;
                for(var j=0; j<temp_prodList.length; j++){
                  if(temp_prodList[j].IDn == this.prodList[i].ProductIDn){
                    include = true;
                    break;
                  }
                }
                if(!include){
                  temp_prodList.push(this.prodList[i]);
                }
              }
            }
          }
        }
        // 2. Set product dropdown options
        this.set_prodList_dropdown(temp_prodList);
        // 3. Reset product selection
        this.reset_cur_product_ID();
      } else {
        // All categories selected
        if(this.cur_department_ID){
          // 1. Get Products of that department (data already exist in prodList)
          if(this.cur_store_ID){
            for(var i=0; i<this.prodList.length; i++){
              if(this.prodList[i].DepartmentIDn == this.cur_department_ID && this.prodList[i].LocationID == this.cur_store_ID){
                let include = false;
                for(var j=0; j<temp_prodList.length; j++){
                  if(temp_prodList[j].IDn == this.prodList[i].ProductIDn){
                    include = true;
                    break;
                  }
                }
                if(!include){
                  temp_prodList.push(this.prodList[i]);
                }
              }
            }
          } else {
            for(var i=0; i<this.prodList.length; i++){
              if(this.prodList[i].DepartmentIDn == this.cur_department_ID){
                let include = false;
                for(var j=0; j<temp_prodList.length; j++){
                  if(temp_prodList[j].IDn == this.prodList[i].ProductIDn){
                    include = true;
                    break;
                  }
                }
                if(!include){
                  temp_prodList.push(this.prodList[i]);
                }
              }
            }
          }
          // 2. Set product dropdown options
          this.set_prodList_dropdown(temp_prodList);
        } else {
          if(this.cur_store_ID){
            // 1.  Get Products of that store (data already exist in prodList)
            for(var i=0; i<this.prodList.length; i++){
              if(this.prodList[i].LocationID == this.cur_store_ID){
                let include = false;
                for(var j=0; j<temp_prodList.length; j++){
                  if(temp_prodList[j].IDn == this.prodList[i].ProductIDn){
                    include = true;
                    break;
                  }
                }
                if(!include){
                  temp_prodList.push(this.prodList[i]);
                }
              }
            }
            // 2. Set product dropdown selection
            this.set_prodList_dropdown(temp_prodList);
          } else {
            // 1. Set product dropdown selection
            this.set_default_prodList_dropdown();
          }
        }
      }
    },
    set_default_storeList_dropdown() {
      this.storeList_dropdown = [];
      this.storeList_dropdown.push({ IDn: "", Name: "" });
      for (var key in this.storeList) {
        let store = {
          IDn: this.storeList[key].LocationID,
          Name: this.storeList[key].LocationName,
        };
        this.storeList_dropdown.push(store);
      }
    },
    set_deptList_dropdown(dept_array){
      this.deptList_dropdown = [];
      this.deptList_dropdown.push({ IDn: "", Name: "" });
      for (var key in this.deptList) {
        if(dept_array.includes(this.deptList[key].IDn)){
          let dept = {
            IDn: this.deptList[key].IDn,
            Name: this.deptList[key].Name1,
          };
          this.deptList_dropdown.push(dept);
          const index = dept_array.indexOf(this.deptList[key].IDn);
          if (index > -1) {
            dept_array.splice(index, 1);
          }
        }
      }
    },
    set_catList_dropdown(cat_array){
      this.catList_dropdown = [];
      this.catList_dropdown.push({ IDn: "", Name: "" });
      for (var key in this.catList) {
        if(cat_array.includes(this.catList[key].IDn)){
          let cat = {
            IDn: this.catList[key].IDn,
            Name: this.catList[key].Name1,
          };
          this.catList_dropdown.push(cat);
          const index = cat_array.indexOf(this.catList[key].IDn);
          if (index > -1) {
            cat_array.splice(index, 1);
          }
        }
      }
    },
    set_prodList_dropdown(prod_array){
      let temp_prod_array = [];
      this.prodList_dropdown = [];
      this.prodList_dropdown.push({ IDn: "", Name: "" });
      // Use temp_prod_array to filter duplicates
      // prod_array may contain duplicated product due to different stores
      // LocationID: ABCD, ProductIDn: 1
      // LocationID: EFGH, ProductIDn: 1
      for(var i=0; i<prod_array.length; i++){
        let include = false;
        for(var j=0; j<temp_prod_array.length; j++){
          if(temp_prod_array[j].ProductIDn == prod_array[i].ProductIDn){
            include = true;
            break;
          }
        }
        if(!include){
          temp_prod_array.push(prod_array[i]);
        }
      }
      prod_array = temp_prod_array;
      // update prodList_dropdown
      for (var i=0; i<this.prodList.length; i++) {
        if(prod_array.includes(this.prodList[i])){
          let prod = {
            IDn: this.prodList[i].ProductIDn,
            Name: this.prodList[i].Name1,
          };
          this.prodList_dropdown.push(prod);
          const index = prod_array.indexOf(this.prodList[i]);
          if (index > -1) {
            prod_array.splice(index, 1);
          }
        }
        
      }
      // let prod = {
      //   IDn: this.prodList[key].ProductIDn,
      //   Name: this.prodList[key].Name1,
      // };
      // this.prodList_dropdown.push(prod);
    },
    set_default_deptList_dropdown(){
      this.deptList_dropdown = [];
      this.deptList_dropdown.push({ IDn: "", Name: "" });
      for (var key in this.deptList) {
        let item_found = false;
        for(var key2 in this.prodList){
          if(this.prodList[key2].DepartmentIDn == this.deptList[key].IDn){
            item_found = true;
            break;
          }
        }
        if(item_found){
          let dept = {
            IDn: this.deptList[key].IDn,
            Name: this.deptList[key].Name1,
          };
          this.deptList_dropdown.push(dept);
        }
      }
    },
    set_default_catList_dropdown(){
      this.catList_dropdown = [];
      this.catList_dropdown.push({ IDn: "", Name: "" });
      for (var key in this.catList) {
        let item_found = false;
        for(var key2 in this.prodList){
          if(this.prodList[key2].CategoryIDn == this.catList[key].IDn){
            item_found = true;
            break;
          }
        }
        if(item_found){
          let cat = {
            IDn: this.catList[key].IDn,
            Name: this.catList[key].Name1,
          };
          this.catList_dropdown.push(cat);
        }
      }
    },
    set_default_prodList_dropdown(){
      this.prodList_dropdown = [];
      this.prodList_dropdown.push({ IDn: "", Name: "" });
      for (var key in this.prodList) {
        let include = false;
        for(var i=0; i<this.prodList_dropdown.length; i++){
          if(this.prodList_dropdown[i].IDn == this.prodList[key].ProductIDn){
            include = true;
            break;
          }
        }
        if(!include){
          let prod = {
            IDn: this.prodList[key].ProductIDn,
            Name: this.prodList[key].Name1,
          };
          this.prodList_dropdown.push(prod);
        }
      }
      this.prodList_dropdown.sort(function(a, b){return a.IDn - b.IDn});
    },
    reset_cur_department_ID(){
      this.cur_department_ID = null;
    },
    reset_cur_category_ID(){
      this.cur_category_ID = null;
    },
    reset_cur_product_ID(){
      this.cur_product_ID = null;
    },
    getNameByID(type, id){
      if(type == "store"){
        for (var key in this.storeList) {
          // skip loop if the property is from prototype
          if (!this.storeList.hasOwnProperty(key)) continue;

          var obj = this.storeList[key];
          for (var prop in obj) {
            // skip loop if the property is from prototype
            if (!obj.hasOwnProperty(prop)) continue;

            if(obj.LocationID == id){
              return obj.LocationName;
            }
          }
        }
      } else if(type == "department"){
        for (var key in this.deptList) {
          // skip loop if the property is from prototype
          if (!this.deptList.hasOwnProperty(key)) continue;

          var obj = this.deptList[key];
          for (var prop in obj) {
            // skip loop if the property is from prototype
            if (!obj.hasOwnProperty(prop)) continue;

            if(obj.IDn == id){
              return obj.Name1;
            }
          }
        }
      } else if(type == "category"){
        for (var key in this.catList) {
          // skip loop if the property is from prototype
          if (!this.catList.hasOwnProperty(key)) continue;

          var obj = this.catList[key];
          for (var prop in obj) {
            // skip loop if the property is from prototype
            if (!obj.hasOwnProperty(prop)) continue;

            if(obj.IDn == id){
              return obj.Name1;
            }
          }
        }
      } else if(type == "product"){
        for(var i=0; i<this.prodList.length; i++){
          if(this.prodList[i].ProductIDn == id){
            return this.prodList[i].Name1;
          }
        }
      }
    },
    async get_list_data() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      if(this.cur_store_ID){
        bodyFormData.append("store_id", this.cur_store_ID);
      }
      if(this.cur_department_ID){
        bodyFormData.append("dept_id", this.cur_department_ID);
      }
      if(this.cur_category_ID){
        bodyFormData.append("cat_id", this.cur_category_ID);
      }
      if(this.cur_product_ID){
        bodyFormData.append("prod_id", this.cur_product_ID);
      }
      if(this.cur_PBarName){
        bodyFormData.append("pBarName", this.cur_PBarName);
      }
      if(this.cur_minQty){
        bodyFormData.append("minQty", this.cur_minQty);
      }
      if(this.cur_maxQty){
        bodyFormData.append("maxQty", this.cur_maxQty);
      }
      if(this.hideZero){
        bodyFormData.append("hideZero", this.hideZero);
      }      
      
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Inventory_summary",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.inventory_data = rt.data.list_data;
              that.formatListData(that.inventory_data);
              if(!that.cur_store_ID){
                that.cur_store_ID_searched = 'All Stores';
              } else {
                that.cur_store_ID_searched = that.getNameByID("store", that.cur_store_ID);
              }
              if(!that.cur_department_ID){
                that.cur_department_ID_searched = 'All Departments';
              } else {
                that.cur_department_ID_searched = that.getNameByID("department", that.cur_department_ID);
              }
              if(!that.cur_category_ID){
                that.cur_category_ID_searched = 'All Categories';
              } else {
                that.cur_category_ID_searched = that.getNameByID("category", that.cur_category_ID);
              }
              if(!that.cur_product_ID){
                that.cur_product_ID_searched = 'All Products';
              } else {
                that.cur_product_ID_searched = that.getNameByID("product", that.cur_product_ID);
              }
              if(that.cur_PBarName){
                that.cur_PBarName_searched = that.cur_PBarName;
              } else {
                that.cur_PBarName_searched = null;
              }
              if(that.cur_maxQty){
                that.cur_maxQty_searched = that.cur_maxQty;
              } else {
                that.cur_maxQty_searched = null;
              }
              if(that.cur_minQty){
                that.cur_minQty_searched = that.cur_minQty;
              } else {
                that.cur_minQty_searched = null;
              }
              that.hideZero_searched = that.hideZero;

            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              // that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_extras() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Inventory_summary/getExtras",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.storeList = rt.data.storeList;
              that.deptList = rt.data.departmentList;
              that.catList = rt.data.categoryList;
              that.prodList = rt.data.productList;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              // that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async form_search() {
      this.get_list_data();
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");

      this.get_extras();
      // this.get_list_data();
    },
    calculate_el_select_width(){
      for(var i = 0; i < document.getElementsByClassName("el-dropdown").length; i++){
        document.getElementsByClassName("el-dropdown")[i].children[0].children[0].style.borderRadius = "0.375rem";
        document.getElementsByClassName("el-dropdown")[i].style.width = document.getElementsByClassName("b-input")[0].parentElement.clientWidth + "px";
        //document.getElementsByClassName("el-dropdown")[i].style.width = (parseFloat(document.getElementsByClassName("el-dropdown")[i].style.width) - 10) + 'px'; // -10 because el-row gutter is 10px
        document.getElementsByClassName("el-dropdown")[i].children[0].children[0].style.height = "43px";
      }
    },
    formatListData(data){
      if(!data) {
        this.list_data = [];
        return;
      }
      if(this.hideZero){
        for (var i = data.length - 1; i == 0; i--) {
          if (data[i].Qty >= 0) { 
            data.splice(i, 1);
          }
        }
      }
      let after = {};
      // console.log("data", data);
      for (var i = 0; i < data.length; i++){
        var storeObj = data[i];
        if (!after.hasOwnProperty(storeObj.LocationID)) {
          after[storeObj.LocationID] = {
            LocationID: storeObj.LocationID,
            LocationName: storeObj.LocationName,
            LocationData: {},
          };
        }
        if (!after[storeObj.LocationID].LocationData.hasOwnProperty(storeObj.CategoryIDn)) {
          after[storeObj.LocationID].LocationData[storeObj.CategoryIDn] = {
            CategoryIDn: storeObj.CategoryIDn,
            CategoryName: this.getNameByID("category", storeObj.CategoryIDn),
            CategoryData: [],
          };
        }
        // Rounding (APrice, Qty, OnOrder, OnHold, StockValue)
        storeObj.APrice = Number(storeObj.APrice).toFixed(2);
        storeObj.Qty = Number(storeObj.Qty).toFixed(2);
        storeObj.OnOrder = Number(storeObj.OnOrder).toFixed(2);
        storeObj.OnHold = Number(storeObj.OnHold).toFixed(2);
        storeObj.StockValue = Number(storeObj.StockValue).toFixed(2);
        after[storeObj.LocationID].LocationData[storeObj.CategoryIDn].CategoryData.push(storeObj);
      }
      

      // console.log("after ", after);
      this.list_data = after;
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
    setTimeout(()=>{
      this.calculate_el_select_width()
    },50); // timer is used to avoid funciton call before left menu is loaded
    window.addEventListener('resize', this.calculate_el_select_width);
  },
  unmounted(){
    window.removeEventListener('resize', this.calculate_el_select_width);
  },
};
</script>
<style>
.content {
  padding-top: 40px;
}
.row {
  margin-right: unset;
  margin-left: unset;
  padding: 0px;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
</style>